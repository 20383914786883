
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import echarts from 'echarts'
import { SmokeList, SmokeDetail } from '../../types/smoke.d'
import { drawPolygon } from '@/utils/formatData'

@Component({
  name: 'DetailMap'
})
export default class DetailMap extends Vue {
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []

  private onlineImg = require('@/assets/icon/smoke/normal.svg')
  private onlineImgCursor = require('@/assets/icon/smoke/normal-big.svg')
  private offImg = require('@/assets/icon/smoke/offline.svg')
  private offImgCursor = require('@/assets/icon/smoke/offline-big.svg')
  private warningImg = require('@/assets/icon/smoke/warning.svg')
  private warningImgCursor = require('@/assets/icon/smoke/warning-big.svg')

  private deviceList: Array<SmokeDetail> = []
  private drawerData: SmokeDetail | null = null

  get projectId () {
    return this.$route.params.projectId as string
  }

  $refs!: {
    statusEcharts: any;
    dayEchart: any;
  }

  created () {
    this.getProjectLocation()
  }

  destroyed (): void {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.clearMap()
    }
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 获取设备信息
  getDeviceList () {
    this.$axios.get<SmokeList>(this.$apis.smoke.selectSmoke, {
      projectId: this.projectId
    }).then((res) => {
      this.deviceList = res.devices || []
      const data = [{
        name: '在线',
        value: res.onlineAmount
      }, {
        name: '离线',
        value: res.offLineAmount
      }]
      this.drawEchart(data)
      this.drawMarker(this.deviceList)
    })
  }

  // 在线离线图
  drawEchart (data: Array<{ name: string; value: string }>) {
    const pieEchart: any = echarts.init(this.$refs.statusEcharts)
    const option = {
      title: {
        text: '状态统计',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 24
      },
      color: ['#1AD1B0', '#D2D2D2'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在线' ? `${item}  ${data[0].value}` : `${item}  ${data[1].value}`
        }
      },

      series: [{
        name: '',
        type: 'pie',
        clockWise: true,
        radius: ['38%', '65%'],
        center: ['35%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: data
      }]
    }
    pieEchart.setOption(option)
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)

      // 获取设备信息
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 获取图片
  getIcon (deviceStatus: string, runStatus: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? (runStatus === '1' ? this.warningImg : this.onlineImg) : this.offImg)
    } else {
      return (deviceStatus === '1' ? (runStatus === '1' ? this.warningImgCursor : this.onlineImgCursor) : this.offImgCursor)
    }
  }

  // 地图打点
  drawMarker (list: Array<SmokeDetail>) {
    this.markers = []
    if (list && list.length > 0) {
      // 循坏将设备marker添加到地图
      list.forEach((item: SmokeDetail, index: number) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, item.runStatus, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    if (this.drawerData === null || (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId)) {
      if (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId) {
        this.drawerClose()
      }
      this.drawerData = e.target.detail
      // 点击切换大图标
      const iconClick = this.getIcon(e.target.detail.deviceStatus, e.target.detail.runStatus, true)
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(iconClick)
    }
    this.drawer = true
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 将图标替换
      if (this.map && this.drawerData) {
        const deviceId = this.drawerData.deviceId || ''
        const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceId === deviceId)
        const icon = this.getIcon(this.drawerData.deviceStatus, this.drawerData.runStatus, false)
        const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }

      // 清空抽屉
      this.drawerData = null
    }
  }
}
